import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Signup from '../components/signup';

const SignupPage = (data) => (
  <Layout>
    <Seo title="Sign Up" />
        <Signup/>
  </Layout>
)

export default SignupPage;