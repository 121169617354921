import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box'

/**
 * Allow the user to identify their role (are they a Service Provider?)
 * @param {*} props 
 * @author BrianCastor
 */
export default function UserRoleSelector(props) {
  //Set default value for userRole
  if (!props.userData['userRole']) {
    let newData = {}
    newData['userRole'] = 'Client';
    props.updateUserData(newData);
  }

  //Set User Role from this choice
  function handleCheckbox(event) {
    let isChecked = event.target.checked;
    let userRole;

    if (isChecked) {
      userRole = 'Service Provider'
    }
    else {
      userRole = 'Client'
    }
    let newData = {};
    newData['userRole'] = userRole;
    props.updateUserData(newData);
  }

  return (
    <div>
      <h3>Sign Up</h3>
        <Grid item xs={12}>
          <FormControlLabel control={<Checkbox name="checkbox1" onChange={(event) => handleCheckbox(event)} />} label="Are you a Service Provider?" checked={props.userData.userRole === 'Service Provider' ? true : false} />
        </Grid>
        {props.userData.userRole === 'Service Provider' && (
            <Box style={{border:'1px solid lightgrey', borderRadius:'3px', marginBottom:'20px'}}>
                <Grid item xs={12} style={{textAlign:'left', padding:'10px'}}>
                    <h4>How we can support Service Providers</h4>
                    We provide our partners with a verified network to connect services with dignity, security, and ease. 
                    <br/>
                    <br/>
                    Through our Data & Insights Portal, our partners can browse nearby verified services on a map and in a list, engage with the people they serve through SMS, and securely collect feedback on their services.
                </Grid>
                <Grid item xs={12} style={{marginTop:'15px', marginBottom:'30px'}}>
                    <Button href="https://www.hope1source.org/learn-more" color="primary" variant="contained">Learn more!</Button>
                </Grid>
            </Box>
        )}
    </div>
  );
}