import React, { useContext } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import { globalContext } from '../GlobalContext';

//Wizard pages
import UserRoleSelector from './userPreferenceComponents/userRoleSelector';
import ServicesSelector from './userPreferenceComponents/servicesSelector';
import DemographicsSelector from './userPreferenceComponents/demographicsSelector';
import SearchRadiusSelector from './userPreferenceComponents/searchRadiusSelector';
import UserInfoSelector from './userPreferenceComponents/userInfo';
import LocationSelector from './userPreferenceComponents/locationSelector';
import StabilizeSituationQuestions from './userPreferenceComponents/stabilizeSituation';

import API from '../API';
import { LoadingDisplay } from './loadingDisplay';

/**
 * Signup 'wizard' that houses the userPreferenceComponents and walks the user through them
 * @param {*} props 
 * @author BrianCastor
 */
export default function Signup(props) {
  const [userData, setUserData] = React.useState({});
  const [currentStep, setCurrentStep] = React.useState(0);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const context = useContext(globalContext);

  //Redirect to /home if user is already signed in
  if (context.isRegistered()) {
    context.navigateSafe('/home');
  }

  //Components to render for each step of wizard
  const wizardSteps = [
    (<UserRoleSelector userData={userData} updateUserData={updateUserData}/>),
    (<ServicesSelector userData={userData} updateUserData={updateUserData} />),
    (<DemographicsSelector userData={userData} updateUserData={updateUserData} />),
    (<LocationSelector userData={userData} updateUserData={updateUserData} />),
    (<SearchRadiusSelector userData={userData} updateUserData={updateUserData} />),
    (<StabilizeSituationQuestions userData={userData} updateUserData={updateUserData} />),
    //(<LanguageSelector userData={userData} updateUserData={updateUserData} />),
    (<UserInfoSelector userData={userData} updateUserData={updateUserData} />),
  ];

  /**
   * Fields to validate (w/ error messages) at each page (based on array index) in the wizard
   */
  const validations = [
    [],
    [], 
    [],
    [
      { field: 'Location__c', error: "Please enter the location you'd like to search from" }
    ], 
    [], 
    [
      { field: 'Most_helpful_to_stability_situation__c', error: "Please fill the field above - 'What service would help stabilize your situation now?'" },
      { field: 'Hope_Rating__c', error: "Please fill the field above - 'How hopeful are you that you will stabilize your situation?'" }
    ],
    //[],
    [
      { field: 'FirstName', error: 'Please enter your First Name' },
      { field: 'LastName', error: 'Please enter your Last Name' },
      { field: 'Email', error: 'Please enter your Email' },
      { field: 'MobilePhone', error: 'Please enter your Mobile Phone' },
      { field: 'Birthdate', error:'Please enter a valid Day of Birth'}
    ],
  ]

  const wizardButtonOptions = {
    'previous': (<Button color="primary" variant="outlined" style={{ margin: '5px' }} onClick={() => previousStep()}>Previous</Button>),
    'next': (<Button color="primary" variant="contained" style={{ margin: '5px' }} onClick={() => nextStep()}>Next</Button>),
    'finish': (<Button color="primary" variant="contained" style={{ margin: '5px' }} onClick={() => finish()}>Finish</Button>)
  };

  /**
   * Method passed into the sub-components to update our wizard state in this component
   */
  function updateUserData(newData) {
    let temp = { ...userData };
    temp = Object.assign(temp, newData);
    setUserData(temp);
  }

  /**
   * Validate the data intered on the sub-form
   */
  function validate() {
    let validation = validations[currentStep];
    let isValid = true;
    validation.forEach(field => {
      let fieldValueExists = Object.keys(userData).includes(field.field) && userData[field.field]
      if (!fieldValueExists) {
        setError(field.error);
        isValid = false;
      }
    })
    return isValid;
  }

  /**
   * Proceed to next step in wizard (check if data is valid first)
   */
  function nextStep() {
    if (!validate()) {
      return;
    }
    setError(null);
    setCurrentStep(currentStep + 1);
  }

  /**
   * Go to previous step in wizard
   */
  function previousStep() {
    setError(null);
    setCurrentStep(currentStep - 1);
  }

  /**
   * Complete the wizard
   */
  function finish() {
    if (!validate()) {
      return;
    }

    setLoading(true);

    //Register the user via the API
    API.register(userData).then(data => {
      data = Object.assign({...userData}, data);
      context.updateUserPreferences(data);
      setLoading(false);
      context.navigateSafe('/home');
    }).catch(err => {
      setLoading(false)
      setError(err);
    })

  }
  
  /**
   * Figure out what buttons to render at bottom of the wizard (next, previous, finish, etc.) based on current step
   */
  function ButtonRenderer() {
    let buttons = null;
    if (currentStep >= wizardSteps.length - 1) {
      buttons = (<div>{wizardButtonOptions['previous']}{wizardButtonOptions['finish']}</div>);
    }
    else if (currentStep === 0) {
      buttons = (<div>{wizardButtonOptions['next']}</div>);
    }
    else {
      buttons = (<div>{wizardButtonOptions['previous']}{wizardButtonOptions['next']}</div>);
    }
    return buttons
  }

  //Loading image
  if (loading) {
    return (<div>
      <div>
        <Container maxWidth="sm" style={{ paddingTop: '40px' }}>
          <Paper component="form" style={{ padding: '10px' }}>
            <div style={{ textAlign: 'center' }}>
              <div>
                <div>
                  <LoadingDisplay />
                </div>
              </div>
            </div>
          </Paper>
        </Container>
      </div>
    </div>
    )
  }

  return (
    <div>
        <Container maxWidth="sm" style={{ paddingTop: '40px', paddingBottom:'80px' }}>
          <Paper component="form" style={{ padding: '10px' }}>
            <div style={{ textAlign: 'center' }}>
              <div>
                <div>
                  <Stepper activeStep={currentStep} style={{padding:'10px'}}>
                    {wizardSteps.map((_, index) => (
                      <Step key={index}>
                        <StepLabel></StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </div>
                <div>
                  {wizardSteps[currentStep]}
                </div>
                <span style={{ color: 'red' }}>{error}</span>
                <div style={{ marginTop: '20px' }}>
                  {ButtonRenderer()}
                </div>
              </div>
            </div>
          </Paper>
        </Container>
    </div>
  );
}