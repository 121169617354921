import React from 'react';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

/**
 * Display to input key user information (name, DOB, etc.)
 * @param {*} props 
 * @author BrianCastor
 */
export default function UserInfoSelector(props) {
  const [selectedDate, setSelectedDate] = React.useState(props.userData.Birthdate || null);

  function handleInputChange(field, newValue) {
    let newData = {};
    newData[field] = newValue;
    props.updateUserData(newData);
  }

  return (
    <div style={{ padding: '0px 10px 10px 10px' }}>
      <h3>Account Information</h3>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField required label="First Name" variant="outlined" fullWidth value={props.userData.FirstName || ''} onChange={(event) => handleInputChange('FirstName', event.target.value)} />
        </Grid>
        <Grid item xs={12}>
          <TextField required label="Last Name" variant="outlined" fullWidth value={props.userData.LastName || ''} onChange={(event) => handleInputChange('LastName', event.target.value)} />
        </Grid>
        <Grid item xs={12}>
          <TextField required label="Email" variant="outlined" fullWidth value={props.userData.Email || ''} onChange={(event) => handleInputChange('Email', event.target.value)} />
        </Grid>
        <Grid item xs={12}>
          <TextField required label="Mobile Phone" variant="outlined" fullWidth value={props.userData.MobilePhone || ''} onChange={(event) => handleInputChange('MobilePhone', event.target.value)} helperText="We will send information on nearby verified services to your phone via SMS. Reply STOP to cancel at anytime."/>
        </Grid>
        <Grid item xs={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            required
            value={selectedDate}
            inputVariant="outlined"
            fullWidth
            label="Date of Birth"
            placeholder="MM-dd-yyyy"
            onChange={(date) => {
              setSelectedDate(date);
              //Only add valid dates to our user context
              if (!date || date.toString() === 'Invalid Date') {
                handleInputChange('Birthdate', null);
              }
              else {
                handleInputChange('Birthdate', date);
              }
            }
            }
            format="MM-dd-yyyy"
          />
        </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <div style={{ marginTop: '20px', textAlign: 'left', fontSize: '12px' }}>
        {/*Directed to use this privacy policy*/}
        To fulfill our mission of connecting people to services, we allow service providers to follow up with SMS and other methods that are HIPAA-compliant with sensitive information. Reply STOP to cancel. Msg and Data rates may apply.
        <br/>
        <br/>
        We keep your information confidential, and will not share it with anyone unless you allow it. Here are our <a href="https://www.hopeonesource.org/privacy" target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="https://www.hopeonesource.org/terms-service" target="_blank" rel="noreferrer">Terms of Service</a>, which you agree to by completing this questionnaire.
        </div>
    </div>
  );
}